<script setup lang="ts">
import { computed, inject } from 'vue'
import { useI18n } from 'vue-i18n'

import IconHeart from '@/components/icons/IconHeart.vue'
import type { PaymentType } from '@/types'
import { getFormattedAmount } from '@/utils/currency'

const props = defineProps<{
  type: PaymentType
  amounts: number[]
  popular?: number
  modelValue?: number
}>()

defineEmits<{
  (e: 'update:modelValue', modelValue: number): void
}>()
defineOptions({
  inheritAttrs: false,
})

const language = inject('language') as string
const currency = inject('currency') as string
const { t } = useI18n()

const isPopularOnSecondLine = computed(
  () =>
    props.popular &&
    props.amounts.length > 3 &&
    props.amounts.indexOf(props.popular) >= Math.ceil(props.amounts.length / 2)
)
</script>

<template>
  <div
    class="row g-2 px-4 amount-grid"
    data-toggle="buttons"
    :class="isPopularOnSecondLine ? 'align-items-start' : 'align-items-end'"
  >
    <template v-for="(amount, index) in amounts" :key="index">
      <div v-if="amounts.length > 3 && index === Math.ceil(amounts.length / 2)" class="w-100" />
      <div class="col m-0 text-center">
        <small
          v-if="
            amount === popular && (amounts.length <= 3 || index < Math.ceil(amounts.length / 2))
          "
          :id="`popular-amount-top-${type}`"
          class="text-secondary"
        >
          {{ t('popular') }}
          <IconHeart :aria-labelledby="`popular-amount-top-${type}`" size="12" />
        </small>
        <button
          class="btn shadow w-100"
          type="button"
          :class="{
            popular: amount === popular,
            active: amount === modelValue,
          }"
          :aria-current="amount === modelValue"
          @click="$emit('update:modelValue', amount)"
        >
          {{ getFormattedAmount(amount, language, currency) }}
        </button>
        <small
          v-if="amount === popular && amounts.length > 3 && index >= Math.ceil(amounts.length / 2)"
          :id="`popular-amount-bottom-${type}`"
          class="text-secondary"
        >
          {{ t('popular') }}
          <IconHeart :aria-labelledby="`popular-amount-bottom-${type}`" size="12" />
        </small>
      </div>
    </template>
  </div>
</template>
