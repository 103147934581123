<template>
  <div class="legal-information">
    <div class="mt-2 fs-7 lh-sm text-placeholder">
      {{ getWidgetOption('legalInformation', 'text') }}
      <div class="text-center">
        <button
          class="btn btn-link btn-sm p-0 lh-sm"
          @click.prevent="legalInformationModal?.show()"
        >
          {{ t('see_more') }}
        </button>
      </div>
    </div>

    <GenericModal
      id="legal-information-modal"
      ref="legalInformationModal"
      :modal-classes="['fade', 'modal-blur']"
      :modal-body-classes="['px-5']"
      hide-on-backdrop-click
      with-close-btn
      size="lg"
      :style="{
        '--modal-header-bg-color': '#f0f5f8',
      }"
      aria-labelledby="legal-information-modal-title"
    >
      <template #header>
        <div class="w-100 text-center">
          <h4 id="legal-information-modal-title" class="my-2">
            {{ getWidgetOption('legalInformation', 'modalTitle') }}
          </h4>
        </div>
      </template>

      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-html="getWidgetOption('legalInformation', 'modalHtml')" />
    </GenericModal>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import GenericModal from '@/components/GenericModal.vue'
import { useWidgetsStore } from '@/store'

const { t } = useI18n()
const { getWidgetOption } = useWidgetsStore()

const legalInformationModal = ref<typeof GenericModal>()
</script>
