<template>
  <div
    v-if="Object.keys(contact).length && contactPrefilled && !isOverlay"
    class="card shadow mb-3 mt-lg--5"
  >
    <div class="card-body linear-background rounded-3 d-flex align-items-center fs-7 lh-sm">
      <div>
        <span class="fs-2 rounded-5 bg-warning-subtle px-3 py-2 me-2 mb-0">👋</span>
      </div>

      <i18n-t
        :keypath="contact?.firstname ? 'reset_prefilled.msg' : 'reset_prefilled.msg_no_firstname'"
        tag="div"
        class="ms-2 mb-0"
      >
        <template #firstname>{{ contact?.firstname }}</template>
        <template #link>
          <a href="?reset">{{ t('reset_prefilled.link') }}</a>
        </template>
      </i18n-t>
    </div>
  </div>

  <div ref="cardForm" class="card card-form shadow overlay-scroll">
    <div class="card-header">
      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <template v-if="!isOverlay || !popupOpened">
            <div v-if="currentStep === 'amount'" class="ms-3 color-primary" style="width: 1.3rem">
              <IconShieldCheck aria-labelledby="header-title" class="icon" style="width: 1.75em" />
            </div>
            <a
              v-else
              class="ms-3 text-white"
              style="width: 1rem"
              role="button"
              href="#back"
              :title="t('back_button')"
              :aria-label="t('back_button')"
              @click.prevent="onClickPrevious"
            >
              <IconChevronLeft class="icon" />
            </a>
          </template>
        </div>
        <div class="col-8 text-center px-0">
          <a id="header-title" class="header-title" href="#" @click.prevent="scrollToTop">{{
            t(`header_title.${paymentTerminology}`)
          }}</a>
        </div>
      </div>
    </div>

    <div ref="cardBody" class="card-body px-0 position-relative linear-background overlay-scroll">
      <Transition
        :name="transitionName"
        @before-enter="onBeforeEnter"
        @enter="onEnter"
        @after-enter="onAfterEnter"
      >
        <AmountChooser
          v-if="currentStep === 'amount'"
          @set-amount="onValidateAmountStep"
          @open-tax-reduction-modal="taxReductionModal?.show()"
        />
        <PushRegular
          v-else-if="currentStep === 'push-regular1'"
          @validate="goToStep('contact')"
          @cancel="goToPreviousStep"
        />
        <ContactForm v-else-if="currentStep === 'contact'" @submit="goToStep('payment')" />
        <PaymentForm
          v-else-if="currentStep === 'payment'"
          @open-tax-reduction-modal="taxReductionModal?.show()"
          @select-custom-payment-method="goToStep('payment-details')"
        />
        <PaymentDetailsForm
          v-else-if="currentStep === 'payment-details'"
          @cancel="goToPreviousStep"
        />
      </Transition>
    </div>
  </div>

  <GenericModal
    id="payment-error-modal"
    ref="paymentErrorModal"
    :modal-classes="['fade', 'modal-blur']"
    :modal-header-classes="['header-with-icon']"
    :modal-body-classes="['text-center']"
    hide-on-backdrop-click
    with-close-btn
    :style="{
      '--modal-header-bg-color': '#f0f5f8',
      '--modal-main-color': '#ef7993',
    }"
  >
    <template #header>
      <div class="header-icon-background">
        <div class="header-icon-container">
          <IconCross class="header-icon" />
        </div>
      </div>
      <div class="header-content w-100 text-center">
        <h4 class="title mb-2 fw-bold" :style="{ color: 'var(--modal-main-color)' }">
          {{ t('error_modal.transaction_failed') }}
        </h4>
        <p class="mb-0">{{ t(`error_modal.message1.${paymentTerminology}`) }}</p>
        <p class="mb-0">{{ t('error_modal.message2') }}</p>
        <p class="mb-0">{{ t('error_modal.message3') }}</p>
      </div>
    </template>

    <button class="btn btn-primary text-uppercase fw-bold" @click="paymentErrorModal?.hide()">
      {{ t(`error_modal.finalize_payment.${paymentTerminology}`) }}
    </button>
  </GenericModal>

  <template v-if="isWidgetEnabled('taxReduction')">
    <GenericModal
      id="tax-reduction-modal"
      ref="taxReductionModal"
      :modal-classes="['fade', 'modal-blur']"
      :modal-body-classes="['px-5']"
      hide-on-backdrop-click
      with-close-btn
      size="lg"
      :style="{
        '--modal-header-bg-color': '#f0f5f8',
      }"
      aria-labelledby="tax-reduction-modal-title"
    >
      <template #header>
        <div class="w-100 text-center">
          <h4 id="tax-reduction-modal-title" class="my-2">{{ t('tax_reduction.modal_title') }}</h4>
        </div>
      </template>

      <TaxReductionInfo
        :organization-type="organizationType"
        :organization-country-code="organizationCountryCode"
        :is-company-donation-enabled="activateCompanyDonation"
        :is-wealth-donation-enabled="widgetHasOption('taxReduction', 'is_wealth_campaign')"
      />
    </GenericModal>
  </template>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { inject, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import AmountChooser from '@/components/AmountChooser.vue'
import ContactForm from '@/components/ContactForm.vue'
import GenericModal from '@/components/GenericModal.vue'
import PaymentDetailsForm from '@/components/PaymentDetailsForm.vue'
import PaymentForm from '@/components/PaymentForm.vue'
import PushRegular from '@/components/PushRegular.vue'
import TaxReductionInfo from '@/components/TaxReductionInfo.vue'
import IconChevronLeft from '@/components/icons/IconChevronLeft.vue'
import IconCross from '@/components/icons/IconCross.vue'
import IconShieldCheck from '@/components/icons/IconShieldCheck.vue'
import { TagManager } from '@/plugins/tag-manager'
import {
  useConfigStore,
  useContactStore,
  usePaymentStore,
  useStore,
  useWidgetsStore,
} from '@/store'
import type {
  AddonField,
  OrganizationType,
  PaymentTerminology,
  PushRegularWidgetOptions,
} from '@/types'
import { getAddonAmount, getAddonField } from '@/utils/addons'
import { isPushRegularWidgetApplicable } from '@/utils/push-regular'

const { t } = useI18n()
const { goToStep, goToPreviousStep } = useStore()
const { contact } = storeToRefs(useContactStore())
const { enabledPaymentTypes } = storeToRefs(useConfigStore())
const { displayErrorModal, popupOpened } = storeToRefs(usePaymentStore())
const {
  currentStep,
  currentStepIndex,
  contactPrefilled,
  addons,
  addonCount,
  customAmount,
  baseAmount,
  totalAmount,
  initialStep,
  currentType,
  originalOneOffAmount,
  parentUrl,
} = storeToRefs(useStore())

const cardForm = ref<HTMLDivElement>()
const cardBody = ref<HTMLDivElement>()
const transitionName = ref<string>('swipe-left')

const { isWidgetEnabled, widgetHasOption, getWidgetOptions } = useWidgetsStore()

const tagManager = inject('tagManager') as TagManager | null

// Config injected from backend
const paymentTerminology = inject<PaymentTerminology>('paymentTerminology')
const organizationType = inject<OrganizationType>('organizationType', 'generic')
const organizationCountryCode = inject<string>('organizationCountryCode', '')
const activateCompanyDonation = inject('activateCompanyDonation', false)
const currency = inject('currency', false)
const isOverlay = inject<boolean>('isOverlay')
const addonFields = inject('addonFields') as AddonField[]

const paymentErrorModal = ref<typeof GenericModal>()
const taxReductionModal = ref<typeof GenericModal>()

function scrollToTop() {
  cardForm.value?.scrollIntoView()
}

function sendCheckoutAnalytics() {
  if (tagManager) {
    tagManager.sendAnalytics({ ecommerce: null })

    const items = []
    if (totalAmount.value) {
      items.push({
        item_id: tagManager.defaults.campaign_code,
        item_name: tagManager.defaults.campaign_name,
        affiliation: tagManager.defaults.affiliation,
        item_category: currentType.value,
        price: baseAmount.value,
        quantity: 1,
      })
      Object.entries(addons.value).forEach(([addon, value]) => {
        const addonField = getAddonField(addonFields || [], addon)
        if (!addonField) {
          return
        }
        const amount = getAddonAmount(addonFields, addon, value)
        items.push({
          item_id: addonField.name,
          item_name: addonField.label,
          affiliation: tagManager.defaults.affiliation,
          item_category: 'addon',
          price: amount,
          quantity: 1,
        })
      })
    }

    tagManager.sendAnalytics({
      event: 'checkout',
      ecommerce: {
        ...tagManager.defaults,
        firststep: initialStep.value,
        step: currentStep.value,
        currency: currency,
        affiliation: tagManager.defaults.affiliation,
        amount_type: !customAmount.value ? 'default' : 'custom',
        initial_amount: originalOneOffAmount.value,
        value: totalAmount.value,
        // type: "Don en mémoire d'un proche",
        donation_type: currentType.value,
        donor_email: contact.value.email,
        items,
      },
    })
  }
}

watch(currentStepIndex, async (newStepIndex, previousStepIndex) => {
  if (newStepIndex > previousStepIndex) {
    transitionName.value = 'swipe-left'
  } else {
    transitionName.value = 'swipe-right'
  }

  scrollToTop()
  sendCheckoutAnalytics()
})

watch(initialStep, sendCheckoutAnalytics)

function onBeforeEnter() {
  // Add overflow-hidden on card-form
  const cardFormEl = cardForm.value as HTMLDivElement
  cardFormEl.classList.add('overflow-hidden')
  // Get the current height of the card body and set it
  const cardBodyEl = cardBody.value as HTMLDivElement
  cardBodyEl.style.height = `${cardBodyEl.scrollHeight}px`
  // Force width of card body elements during transition
  Array.from(cardBodyEl.children).forEach((el) => {
    ;(el as HTMLElement).style.width = `${el.clientWidth}px`
  })
}

function onEnter(newEl: Element) {
  // Get the height of the new element being inserted and set it to the parent
  const cardBodyEl = cardBody.value as HTMLDivElement
  cardBodyEl.style.height = `${(newEl as HTMLDivElement).scrollHeight}px`
}

function onAfterEnter() {
  // Remove overflow-hidden from card-form
  const cardFormEl = cardForm.value as HTMLDivElement
  cardFormEl.classList.remove('overflow-hidden')
  // Set the card body height back to auto
  const cardBodyEl = cardBody.value as HTMLDivElement
  cardBodyEl.style.height = 'auto'
}

function onValidateAmountStep() {
  if (
    isWidgetEnabled('pushRegular') &&
    isPushRegularWidgetApplicable(
      baseAmount.value,
      enabledPaymentTypes.value,
      currentType.value,
      getWidgetOptions('pushRegular') as PushRegularWidgetOptions,
      addonCount.value
    )
  ) {
    goToStep('push-regular1')
    return
  }

  goToStep('contact')
}

function onClickPrevious() {
  goToPreviousStep()
  contactPrefilled.value = false
}

onMounted(() => {
  if (displayErrorModal.value) {
    paymentErrorModal.value?.show()
  }
  sendCheckoutAnalytics()

  if (isOverlay) {
    // Listen to parent sending parentUrl and store it
    window.addEventListener(
      'message',
      (event: MessageEvent) => {
        if (event.data.parentUrl) {
          parentUrl.value = event.data.parentUrl
        }
      },
      { once: true }
    )

    // Send message to parent when component is loaded
    window.parent.postMessage('rgive-form-loaded', '*')
  }
})
</script>
