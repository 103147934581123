import type { PiniaPluginContext, Store } from 'pinia'
import { inject } from 'vue'

import { RESET_CHOSEN_AMOUNT } from '@/store'
import type {
  AddonField,
  AttachedModel,
  FieldConfig,
  FormFieldsDisplay,
  PaymentType,
  WidgetName,
} from '@/types'

interface Param {
  alias: string
  type: BooleanConstructor | NumberConstructor | StringConstructor
}

type ExtractedParams = Record<string, any>

/**
 * Extract campaign config overrides from GET parameters
 *
 * Validate GET parameters, extract them from URL via History API and return them for use.
 *
 * NB: Every keyword used in this class must be added to donation.models.form_field.RESERVED_NAMES to avoid
 * conflicts with custom form field names
 *
 */

function extractAllowedOverrides(storages: Storage[]) {
  let extractedState: ExtractedParams | null = null

  function patchStore(store: Store, data: any) {
    /* istanbul ignore next -- @preserve */
    if (import.meta.env.DEV) {
      console.info(`${store.$id} from local storage`, { ...store.$state })
      console.info(`${store.$id} from URL`, data)
    }
    store.$patch(data)

    // Force the store to persist now to actually create the data
    /* istanbul ignore else -- @preserve */
    if (store.$persist) {
      store.$persist()
    }
  }

  return function ({ store }: PiniaPluginContext) {
    const params = new URLSearchParams(location.search)
    const formConfig = inject('formFieldsDisplay') as FormFieldsDisplay
    const addonsConfig = inject('addonFields') as AddonField[]

    if (params.has('reset')) {
      params.delete('reset')
      store.$reset()
      storages.forEach((s) => s.clear())
    }

    if (extractedState === null) {
      extractedState = {}
      handleErrorModal(extractedState)
      handleWidgets(extractedState)
      handleContactKey(extractedState)

      if (inject('activateUrlOverride')) {
        handleReference(extractedState)
        handleBasic(extractedState)
        handleGrids(extractedState)
        handlePaymentTypes(extractedState)
        handleAmount(extractedState)
        handleContact(extractedState)
        handlePaymentData(extractedState)
        handleAddons(extractedState)

        // Replace current url with remaining parts
        history.replaceState(
          null,
          '',
          `${location.pathname}${params.toString() ? '?' : ''}${params.toString()}${location.hash}`
        )
      }
    }

    if (extractedState !== null && Object.keys(extractedState).length) {
      let config
      let displayPaymentErrorModal
      let disabledWidgetsList = []
      let paymentOrCommitmentUUID
      let prefill
      let extractedContactKey

        // Split what we extracted
      ;(({
        enabledPaymentTypes,
        activateFreeAmount,
        popularOneoff,
        popularRegular,
        amountsOneoff,
        amountsRegular,
        paymentError,
        disabledWidgets,
        reference,
        contactKey,
        ...rest
      }) => {
        config = Object.fromEntries(
          Object.entries({
            enabledPaymentTypes,
            activateFreeAmount,
            popularOneoff,
            popularRegular,
            amountsOneoff,
            amountsRegular,
          }).filter(([, v]) => v !== undefined)
        )
        displayPaymentErrorModal = paymentError === true
        disabledWidgetsList = disabledWidgets
        paymentOrCommitmentUUID = reference
        extractedContactKey = contactKey
        prefill = rest
      })(extractedState)

      // Patch each store as needed
      if (store.$id === 'config' && Object.keys(config).length) {
        patchStore(store, config)
      } else if (store.$id === inject('basketCookieName')) {
        const storeData: any = { displayErrorModal: displayPaymentErrorModal }
        if (paymentOrCommitmentUUID) {
          storeData.reference = paymentOrCommitmentUUID
        }
        patchStore(store, storeData)
      } else if (store.$id === 'widgets' && disabledWidgetsList) {
        patchStore(store, { disabledWidgets: disabledWidgetsList })
      } else if (store.$id === 'contact') {
        store.prefill(prefill?.contact || {}, extractedContactKey)
      } else if (store.$id === 'store') {
        patchStore(store, prefill)
      }
    }

    function handleReference(extractedState: ExtractedParams) {
      const value = params.get('reference')
      if (
        value !== null &&
        /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(value)
      ) {
        extractedState.reference = value
        params.delete('reference')
      }
    }

    function handleContactKey(extractedState: ExtractedParams) {
      const value = params.get('contact_key')
      if (value) {
        extractedState.contactKey = value
        params.delete('contact_key')
      }
    }

    function handleBasic(extractedState: ExtractedParams) {
      const defaultTypes: Record<string, Param> = {
        activateFreeAmount: {
          alias: 'free',
          type: Boolean,
        },
        popularOneoff: {
          alias: 'popular_o',
          type: Number,
        },
        popularRegular: {
          alias: 'popular_r',
          type: Number,
        },
        desiredStep: {
          alias: 'step',
          type: String,
        },
      }

      // Handle basic types
      Object.entries(defaultTypes)
        .filter(([, { alias }]) => params.has(alias))
        .forEach(([name, { alias, type }]) => {
          const value = params.get(alias) as string
          if (type === Boolean && isBool(value)) {
            extractedState[name] = value === '1'
            params.delete(alias)
          } else if (type === Number) {
            const floatValue = parseFloat(value)
            if (!Number.isNaN(floatValue)) {
              extractedState[name] = floatValue
              params.delete(alias)
            }
          } else {
            extractedState[name] = value
            params.delete(alias)
          }
        })

      if (extractedState?.desiredStep === 'payment') {
        extractedState.contactPrefilled = true
      }
    }

    function handleGrids(extractedState: ExtractedParams) {
      Object.entries({
        amountsOneoff: { alias: 'amounts_o', suffix: 'Oneoff' },
        amountsRegular: { alias: 'amounts_r', suffix: 'Regular' },
      })
        .filter(([, { alias }]) => params.has(alias))
        .forEach(([name, { alias, suffix }]) => {
          const values = (params.get(alias) as string).split(',')
          const minimumAllowed = inject(`minimumAmount${suffix}`) as number
          const allowedValues = values.filter(
            (v) => !Number.isNaN(parseFloat(v)) && parseFloat(v) >= minimumAllowed
          )
          extractedState[name] = allowedValues.map(Number)
          params.delete(alias)
        })
    }

    function handlePaymentTypes(extractedState: ExtractedParams) {
      Object.entries({
        enabledPaymentTypes: {
          alias: 'types',
          allowedValues: ['one-off', 'regular', 'one-off_regular'],
        },
        currentType: { alias: 'type', allowedValues: ['one-off', 'regular'] },
      })
        .filter(
          ([, { alias, allowedValues }]) =>
            params.has(alias) && allowedValues.includes(params.get(alias) as string)
        )
        .forEach(([name, { alias }]) => {
          extractedState[name] = params.get(alias)
          params.delete(alias)
        })
    }

    function handleAmount(extractedState: ExtractedParams) {
      // Handle basic types
      const value = parseFloat(params.get('amount') as string)
      const paymentType =
        extractedState.currentType || (inject('defaultPaymentType', 'one-off') as PaymentType)
      const suffix = paymentType === 'one-off' ? 'Oneoff' : 'Regular'
      const minimumAllowed = inject(`minimumAmount${suffix}`) as number
      if (!Number.isNaN(value) && value > minimumAllowed) {
        // Initialise amounts
        if (
          (paymentType === 'one-off' &&
            (extractedState.amountsOneoff || inject('amountsOneoff', [])).includes(value)) ||
          (paymentType === 'regular' &&
            (extractedState.amountsRegular || inject('amountsRegular', [])).includes(value))
        ) {
          extractedState.chosenAmount = { ...RESET_CHOSEN_AMOUNT, [paymentType]: value }
          extractedState.customAmount = null
        } else {
          extractedState.chosenAmount = { ...RESET_CHOSEN_AMOUNT }
          extractedState.customAmount = value
        }
        params.delete('amount')
      }
    }

    function handleCustomParam(attachedModels: AttachedModel[]) {
      const obj: Record<string, string | string[] | boolean | number | null> = {}
      Object.values(formConfig).map((configs) =>
        configs
          .filter(
            ({ name, attached_to }) => params.has(name) && attachedModels.includes(attached_to)
          )
          .forEach((fieldConfig) => handleUrlParam(fieldConfig, obj))
      )
      return obj
    }

    function handleContact(extractedState: ExtractedParams) {
      const contact = handleCustomParam(['contact'])
      if (Object.keys(contact).length) {
        extractedState.contact = contact
      }
    }

    function handlePaymentData(extractedState: ExtractedParams) {
      const paymentData = handleCustomParam(['payment', 'commitment', 'payment_commitment'])
      if (Object.keys(paymentData).length) {
        extractedState.paymentData = paymentData
      }
    }

    function handleAddons(extractedState: ExtractedParams) {
      const addonData: Record<string, string | boolean | number> = {}

      addonsConfig
        .filter(({ name }) => params.has(name))
        .forEach((addonConfig) => handleUrlParam(addonConfig, addonData))

      if (Object.keys(addonData).length) {
        extractedState.addons = addonData
      }
    }

    function handleErrorModal(extractedState: ExtractedParams) {
      if (params.has('payment_error')) {
        if (params.get('payment_error') === '1') {
          extractedState.paymentError = true
        }
        params.delete('payment_error')
      }
    }

    function handleWidgets(extractedState: ExtractedParams) {
      const disabledWidgets: WidgetName[] = []
      if (params.has('suggest_r')) {
        if (params.get('suggest_r') === '0') {
          disabledWidgets.push('pushRegular')
        }
        params.delete('suggest_r')
      }

      if (disabledWidgets) {
        extractedState.disabledWidgets = disabledWidgets
      }
    }

    function handleUrlParam(
      param: FieldConfig | AddonField,
      obj: Record<string, string | string[] | boolean | number | null> = {}
    ) {
      let removeParam = false
      const { name, type, choices } = param

      if (type === 'multiselect') {
        obj[name] = params.getAll(name)
        removeParam = true
      } else {
        const value = params.get(name)

        if (type === 'boolean') {
          if (isBool(value)) {
            obj[name] = value === '1'
            removeParam = true
          }
        } else if (type === 'number' && value) {
          const numVal = parseFloat(value)
          if (!Number.isNaN(numVal)) {
            obj[name] = numVal
            removeParam = true
          }
        } else if (choices && value) {
          if (choices.map(([v]) => v).includes(value)) {
            obj[name] = value
            removeParam = true
          }
        } else {
          obj[name] = value
          removeParam = true
        }
      }
      if (removeParam && !(param as any).is_utm_field) {
        params.delete(name)
      }
    }

    function isBool(value: string | null) {
      return ['0', '1'].includes(value as string)
    }
  }
}

export default extractAllowedOverrides
